import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";

import {
  DefaultOverlayPartialAppState,
  initialDefaultOverlayState,
} from "@pimo/pimo-components";

import {
  ActivityAttributes,
  Attachment,
  Dashboard,
  FilterData,
  OEProjectAttributes,
  OEProjectAttributesForEditPage,
  OEProjectOverview,
  Profile,
  ProgramAttributes,
} from "dlp-types";
import { APP_KEY } from "../app-key";
import { APP_ROUTES } from "./constants";
import { STRAPI_URL } from "./env";
import { createOverlay } from "./helpers/create-overlay";
import {
  fetchDashboardCalculations,
  fetchOeProjectOverview,
  fetchOEProjects,
  fetchProgram,
  fetchUserProfile,
} from "./helpers/dlp-fetch-helper";
import { isUserAdmin } from "./helpers/is-user-admin";
import { DashboardPlugin } from "./pages/dashboard-plugin";
import { HomePlugin } from "./pages/home-plugin";
import { NoDataPlugin } from "./pages/no-data.plugin";
import { OverviewPlugin } from "./pages/overview-plugin";
import { PDFDashboardPlugin } from "./pages/pdf-dashboard-plugin";
import { ReportPlugin } from "./pages/report-plugin";
import { theme } from "./theme";

export type DLPAppState = DefaultOverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  UniversalAppState & {
    sideMenuEntries?: OEProjectAttributes[];
    dashboard?: Dashboard;
    oeProjects?: OEProjectAttributes[];
    program?: ProgramAttributes;
    oeProjectOverview?: OEProjectOverview;
    filterDialogData?: FilterData;
    filterDialogPossibleOptions?: {
      oeProjects: string[];
      owners: string[];
    };
    currentOEProject?: OEProjectAttributes;
    reportPluginPopupOpen?: boolean;
    reportPluginPopupContent?: ActivityAttributes;
    attachments?: Attachment[];
    editDialogData?: OEProjectAttributesForEditPage;
  };

// Plugins
const loginPlugin = new LoginPlugin<Profile, DLPAppState>(
  "/",
  STRAPI_URL,
  "Data Leakage Prevention (DLP)"
);

const homePlugin = new HomePlugin<DLPAppState>();
const dashboardPlugin = new DashboardPlugin();
const overviewPlugin = new OverviewPlugin();
const reportPlugin = new ReportPlugin();
const pdfDashboardPlugin = new PDFDashboardPlugin(
  dashboardPlugin,
  reportPlugin
);
// App
const app = App.create<DLPAppState>(
  composeInitialState<DLPAppState>({
    ...initialDefaultOverlayState,
    ...loginPlugin.getInitialState(),
    isLoading: false,
    isSidebarOpen: true,
  }),
  APP_KEY
);
app.setTheme(theme);

// Overlay
const overlay = createOverlay(app);

// LIFECYCLES
overlay.on("overlay:logout", () => {
  loginPlugin.logout();
});

app.registerPlugin(loginPlugin);
app.registerPlugin(dashboardPlugin);
app.registerPlugin(overviewPlugin);
app.registerPlugin(homePlugin);
app.registerPlugin(reportPlugin);
app.registerPlugin(pdfDashboardPlugin);
app.registerPlugin(new NoDataPlugin<DLPAppState>());

dashboardPlugin.route?.on("load", async () => {
  const oeProjects = await fetchOEProjects();
  const program = await fetchProgram();
  const dashboard = await fetchDashboardCalculations();
  const state: DLPAppState = app.getAppState();
  const oeProjectOverview = await fetchOeProjectOverview();
  app.setAppState({
    ...state,
    dashboard,
    program,
    oeProjects,
    oeProjectOverview,
    filterDialogData: {
      projectsFilter: [],
      overdueFilter: [],
      govLeadFilter: [],
      atRiskFilter: [],
      updateStatusFilter: [],
      maturityFilter: [],
      searchQueryFilter: "",
      dueYearFilter: [],
      dueQuarterFilter: [],
      regionFilter: [],
      localFilter: [],
    },
  });

  // if route is loaded and user is not admin, redirect them to the first OE report
  if (
    !isUserAdmin(app.getAppState().userProfile) &&
    app.getAppState().userProfile?.regionsUserHasAccessTo?.length === 0
  ) {
    if (oeProjects.length > 0) {
      app.navigate(`/reports/${oeProjects?.[0]?.id ?? 0}/dashboard`);
    } else {
      app.navigate(`/no-data`);
    }
  }
});

overviewPlugin.route?.on("load", async () => {
  const oeProjectOverview = await fetchOeProjectOverview();
  const oeProjects = await fetchOEProjects();

  app.setAppState({
    ...app.getAppState(),
    oeProjectOverview,
    oeProjects,
    filterDialogPossibleOptions: {
      oeProjects: oeProjects.map((oe) => oe.name),
      owners: oeProjects
        .map(
          (oe) =>
            oe.team.find(
              (teamMember) => teamMember.role?.name === "DLP Contact"
            )?.person?.name ?? ""
        )
        .filter(Boolean),
    },
  });
});

pdfDashboardPlugin.route?.on("load", () => {
  // check if `history.state` is populated.
  // `{ usr? {} }` is injected via `react-router-dom`.
  if ((window.history.state as { usr?: { print?: boolean } })?.usr?.print) {
    window.onafterprint = () => {
      if (window.location.pathname.includes("pdf")) {
        // eslint-disable-next-line
        // @ts-ignore - this is fine, `react-router-dom` supports `-1` to navigate to the previous page.
        app.navigate(-1, { state: null });
      }
    };
    // wait until the main thread is clear.
    // this also includes assets that are being fetched.
    window.requestIdleCallback(() => window.print(), { timeout: 1_000 });
  }
});

homePlugin.route?.on("load", async () => {
  const isLoggedIn = await loginPlugin.isLoggedIn();

  if (isLoggedIn) {
    // if route is loaded and user is not admin, redirect them to the first OE report
    if (!isUserAdmin(app.getAppState().userProfile)) {
      if (app.getAppState().userProfile?.regionsUserHasAccessTo?.length) {
        app.navigate(APP_ROUTES.dashboard);
      } else {
        const oeProjects = await fetchOEProjects();
        if (oeProjects.length > 1) {
          app.navigate(APP_ROUTES.overview);
        } else if (oeProjects.length > 0) {
          app.navigate(`/reports/${oeProjects?.[0]?.id ?? 0}/dashboard`);
        } else {
          app.navigate(`/no-data`);
        }
      }
    } else {
      app.navigate(APP_ROUTES.dashboard);
    }
  } else {
    app.setAppState({ ...app.getAppState(), isLoading: false });
    app.navigate(APP_ROUTES.login);
  }
});

loginPlugin.on("login-status-changed", async (evt) => {
  if (!evt.payload?.isLoggedIn) {
    return;
  }

  const dashboard = await fetchDashboardCalculations();
  const oeProjects = await fetchOEProjects();
  const program = await fetchProgram();
  const userProfile = await fetchUserProfile();
  const state = app.getAppState();

  app.setAppState({
    ...state,
    isLoading: false,
    dashboard,
    oeProjects: oeProjects ?? [],
    sideMenuEntries: oeProjects ?? [],
    program,
    userProfile,
  });
  // lazily populate the pdf page after fetching all data
  pdfDashboardPlugin.render();
});

const RenderedApp = app.render();

export default RenderedApp;
