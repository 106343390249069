import { App } from "@pimo/pimo-app-builder";
import { APP_ROUTES } from "../constants";

import type { DLPAppState } from "../app";
import {
  fetchOEProjects,
  fetchOeProjectOverview,
  fetchProgram,
  updateProgramReportingDate,
} from "./dlp-fetch-helper";
import { isUserAdmin } from "./is-user-admin";
import { formatDate } from "dlp-utils";
import { DLPOverlay } from "../components/overlay/dlp-overlay";

export const createOverlay = (app: App<DLPAppState>) => {
  const view = app.createOverlayView({ name: "overlay" });
  const overlay = view.addComponent({ component: DLPOverlay });

  overlay.on("overlay:toggle-sidebar", () => {
    const state = app.getAppState();

    app.setAppState({
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    });
  });

  overlay.on("overlay:click-logo", async () => {
    if (isUserAdmin(app.getAppState().userProfile)) {
      app.navigate(APP_ROUTES.dashboard);
    } else {
      const oeProjects = await fetchOEProjects();
      app.navigate(`/reports/${oeProjects?.[0]?.id ?? 0}/dashboard`);
    }
  });

  overlay.on("overlay:toggle-date-picker", async ({ payload }) => {
    if (!payload) {
      return;
    }
    await updateProgramReportingDate(
      formatDate(new Date(payload?.reportingDate ?? ""))
    );
    const oeProjects = await fetchOEProjects();
    const oeProjectOverview = await fetchOeProjectOverview();
    const program = await fetchProgram();
    const state: DLPAppState = app.getAppState();

    app.setAppState({
      ...state,
      oeProjects: oeProjects ?? [],
      oeProjectOverview,
      program,
    });
  });

  overlay.on("overlay:download-report", () => {
    app.navigate("/pdf", { state: { print: true } });
  });

  overlay.mapState((state) => ({
    header: {
      logo: {
        big: "allianz.svg",
        small: "allianz-small.svg",
      },
      username: `${state.userProfile?.name ?? "n/a"} (${
        state.userProfile?.email ?? "n/a"
      })`,
    },
    canUserChangeCycle: isUserAdmin(state.userProfile),
    menuEntries: [
      {
        icon: "dashboard.svg",
        level: 0,
        link: APP_ROUTES.dashboard,
        title: "DLP Dashboard",
        onlyForAdmin: true,
      },
      {
        icon: "clipboard.svg",
        level: 0,
        link: APP_ROUTES.overview,
        title: "DLP Overview",
        onlyForAdmin: false,
      },
      {
        icon: "barchart.svg",
        items:
          state?.sideMenuEntries
            ?.sort((projectA, projectB) =>
              projectA.name.localeCompare(projectB.name)
            )
            .map(({ id, name }) => ({
              level: 1,
              link: `/reports/${id ?? 0}/dashboard`,
              title: name,
              active: window.location.pathname.includes(
                `/reports/${id ?? 0}/dashboard`
              ),
            })) ?? [],
        level: 0,
        title: "OE List",
      },
    ].filter(
      ({ onlyForAdmin }) =>
        !onlyForAdmin ||
        isUserAdmin(state.userProfile) ||
        state.userProfile?.regionsUserHasAccessTo?.length
    ),
    reportingDate: state.program?.reportingDate,
    isSidebarOpen: state.isSidebarOpen,
  }));

  return overlay;
};
